import { SvgIcon } from '@material-ui/core';
import React from 'react';
import IconSource from './svgs/master.svg';
import { TDCIconProps } from '../../icons/TDCIcon';

export const MasterCardIcon: React.FC<TDCIconProps> = React.memo((props: TDCIconProps) => (
    <SvgIcon component={IconSource} viewBox="0 0 26 18" {...props} />
));

export default MasterCardIcon;
