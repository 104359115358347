import { requests } from '../../../service/requests';
import { getPublicShopAPIUrl } from '../../../helpers/api-url';
import { CompanyAddressData } from '../../../components/CvrLookup';
import { CheckoutResponse } from '../../../types/generated/api.interface';

export const finishCheckout = async (basketId: string): Promise<CheckoutResponse> => {
    const response = await requests.post<CheckoutResponse>(
        `${getPublicShopAPIUrl()}checkout?basketId=${encodeURIComponent(basketId)}`,
    );
    return response.data;
};

export const getCompanyDataFromCVRNumber = async (cvrInput: string): Promise<CompanyAddressData> => {
    const dataRequest = await requests.get<CompanyAddressData>(
        `${getPublicShopAPIUrl()}cvr/${encodeURIComponent(cvrInput)}`,
    );
    return dataRequest.data;
};
