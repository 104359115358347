import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useThrottle } from '@react-hook/throttle'
import { getPublicShopAPIUrl } from '../../helpers/api-url'
import { requests } from '../../service/requests'
import SearchIcon from '../../icons/generated/SearchIcon'
import { Box, makeStyles } from '@material-ui/core'

export type CompanyAddressData = {
  cvrNumber: string
  address: {
    name: string
    attention: string
    address: string
    zip: string
    city: string
  }
  isBlackListed: boolean
  isValid: boolean
}

type CvrLookupProps = {
  callback: (result: CompanyAddressData | null) => void
  label: string
  noOptionsText: string
  loadingText: string
  defaultValue?: CompanyAddressData
  loading?: boolean
}

let cancelToken: () => void | undefined

const useStyles = makeStyles(theme => ({
  searchIcon: {
    marginRight: theme.spacing(1),
    '& > svg': {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },
}))

const CvrLookup: React.FC<CvrLookupProps> = React.memo(
  (props: CvrLookupProps): JSX.Element => {
    const {
      callback,
      label,
      noOptionsText,
      loadingText,
      defaultValue,
      loading: loadingFromProps,
    } = props
    const [open, setOpen] = useState(false)
    const [focus, setFocus] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [options, setOptions] = useState<CompanyAddressData[]>([])
    const [throttledInputValue, setThrottledInputValue] = useThrottle(
      '',
      2,
      true,
    )
    const [rawInputValue, setRawInputValue] = useState('')
    const classes = useStyles()

    const getNewData = async (searchString: string) => {
      setLoadingData(true)

      // If a query was already running, kill it now.
      if (cancelToken) {
        cancelToken()
      }

      if (searchString.length < 3) {
        setOptions([])
        setLoadingData(false)
        return
      }

      try {
        const dataRequest = requests.cancellableGet<CompanyAddressData[]>(
          `${getPublicShopAPIUrl()}cvr/search?term=${encodeURIComponent(
            searchString,
          )}`,
        )

        cancelToken = dataRequest.cancel
        const response = await dataRequest.request

        setOptions(response.data)

        // Open the selector only if the field has focus.
        if (focus) {
          setOpen(true)
        }
      } catch {
        setOptions([])
      }

      setLoadingData(false)
    }

    // Whenever the throttled value actually changes, we should query the server for new options.
    useEffect((): (() => void) => {
      if (throttledInputValue) {
        getNewData(throttledInputValue)
      } else {
        setOptions([])
        setOpen(false)
      }

      // A bit of cleanup. Beware that this'll kill whatever request was running last, so don't
      // use more than one CVR lookup component at a time, okaaaay?
      return (): void => {
        if (cancelToken) {
          cancelToken()
        }
      }
    }, [throttledInputValue])

    // If we get a new default value through our props, we should respect that.
    useEffect(() => {
      if (defaultValue && !rawInputValue) {
        setRawInputValue(defaultValue.address.name)
        setThrottledInputValue(defaultValue.address.name)
      }
    }, [defaultValue])

    return (
      <Autocomplete
        id="cvr-field"
        open={open}
        onOpen={() => {
          setFocus(true)
          if (options.length) setOpen(true)
        }}
        onClose={() => {
          setFocus(false)
          setOpen(false)
        }}
        getOptionSelected={(option, value) =>
          option.cvrNumber === value.cvrNumber
        }
        getOptionLabel={option =>
          `${option.address.name} (${option.cvrNumber})`
        }
        noOptionsText={noOptionsText}
        loadingText={loadingText}
        options={options}
        loading={loadingData || loadingFromProps}
        defaultValue={defaultValue}
        onChange={(_event, newValue: null | CompanyAddressData) =>
          callback(newValue)
        }
        onInputChange={(_event, newInputValue) => {
          setRawInputValue(newInputValue)
          setThrottledInputValue(newInputValue.trim())
        }}
        inputValue={rawInputValue}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
              ),
              endAdornment: (
                <React.Fragment>
                  {loadingData || loadingFromProps ? (
                    <Box mr={-3}>
                      <CircularProgress color="inherit" size={20} />
                    </Box>
                  ) : null}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    )
  },
)

CvrLookup.displayName = 'CvrLookup'

export { CvrLookup }
