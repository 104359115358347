import React, { useEffect, useState } from 'react'
import { useBasket } from '../modules/basket'
import { Link as RouterLink } from 'gatsby'
import { routes } from '../components/Router/routes'
import { translate } from '../helpers/translate'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Form } from 'react-final-form'
import { TextField, showErrorOnBlur } from 'mui-rff'
import { TextFieldProps } from 'mui-rff/src/TextField'
import ErrorFilledIcon from '../icons/ErrorFilled'
import { validEmail } from '../utils/validEmail'
import AmericanExpress from '../icons/payment-methods/AmericanExpress'
import Dankort from '../icons/payment-methods/Dankort'
import Visa from '../icons/payment-methods/Visa'
import MasterCard from '../icons/payment-methods/MasterCard'
import { MiniBasketLineProductInfo } from '../components/MiniBasketLineProductInfo/MiniBasketLineProductInfo'
import { BasketPriceTable } from '../components/BasketPriceTable'
import { GetHelp } from '../components/GetHelp'
import { ErrorMessage } from '../components/ErrorMessage'
import { CompanyAddressData, CvrLookup } from '../components/CvrLookup'
import {
  finishCheckout,
  getCompanyDataFromCVRNumber,
} from '../modules/checkout/service'
import Layout from '../Layout/Layout'
import Seo from '../components/Seo'
import { usePageViewAnalytics } from '../hooks/useAnalytics'
import { getTermsAndConditions } from '../service/get-terms-and-conditions'
import useSWR from 'swr'

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
      paddingTop: theme.spacing(3),
    },
  },
  fieldWrapper: {
    [theme.breakpoints.up('md')]: {
      width: '59%',
    },
  },
  textField: {
    '& textarea, & input': {
      minWidth: 150,
    },
  },
  errorIcon: {
    fontSize: '1.1em',
    verticalAlign: 'middle',
    marginRight: '.5em',
  },
  termsButton: {
    padding: 0,
    textDecoration: 'underline',

    '&:hover': {
      textDecoration: 'none',
    },
  },
  paymentIconWrapper: {
    display: 'inline-flex',
    width: 42,
    height: 28,
    background: 'white',
    borderRadius: 3,
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  paymentIcon: {
    height: 'auto',
  },
  productList: {
    '& > div:not(:first-child)': {
      marginTop: theme.spacing(1.5),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
}))

type CheckoutFormFieldNames = 'attention' | 'email' | 'phone' | 'cvr'

type FormDataObject = { [K in CheckoutFormFieldNames]: string }

type CvrStates = 'IDLE' | 'VALID' | 'INVALID' | 'LOADING'
type AcceptanceStates = 'IDLE' | 'ACCEPTED' | 'NOT_ACCEPTED'

const CheckoutPage = () => {
  const basket = useBasket()
  const classes = useStyles()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const theme = useTheme()
  const isMediumScreenOrLarger = useMediaQuery(theme.breakpoints.up('sm'))
  const isBigScreenOrLarger = useMediaQuery(theme.breakpoints.up('md'))
  const [isAlternativeDeliveryAddress, setIsAlternativeDeliveryAddress] =
    useState(false)
  const [termsModalOpen, setTermsModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [termsAccepted, setTermsAccepted] = useState<AcceptanceStates>('IDLE')
  const [cvrData, setCvrData] = useState<CompanyAddressData>()
  const [cvrState, setCvrState] = useState<CvrStates>('IDLE')
  const [defaultCvrSearchValue, setDefaultCvrSearchValue] =
    useState<CompanyAddressData>()

  const { lines } = basket
  const items = lines?.map(product => {
    return {
      brand: product.brand,
      name: product.name,
      id: product.variantId,
      quantity: `${product.amount}`,
      price: `${product.unitPrice}`,
    }
  })

  // Adobe Analytics - Global Variables
  usePageViewAnalytics({
    trackingPageInfo: {
      pageName: 'checkud - shop',
      siteSection: 'shop',
      subSection1: 'checkud',
      pageType: 'checkout',
      id: 'checkout-shop',
      pageVariant: 'SMB',
    },
    trackingProductsInfo: {
      customEvent: 'checkout',
      items,
    },
  })

  const sharedFieldProps: Partial<TextFieldProps> = {
    className: classes.textField,
    variant: 'outlined',
    margin: 'normal',
    fullWidth: true,
    showError: showErrorOnBlur,
    onBlur: (e: React.FocusEvent<HTMLInputElement>): void => {
      if (basket[e.target.name] !== e.target.value) {
        basket.setAddressInfo({ [e.target.name]: e.target.value })
      }
    },
  }

  const updateAddressInfoInBasket = async (values: FormDataObject) => {
    return basket.setAddressInfo({
      attention: values['attention'],
      cvr: cvrData?.cvrNumber,
      phone: values['phone'],
      email: values['email'],
    })
  }

  const { data: termsAndConditionsContent } = useSWR<string>(
    termsModalOpen ? getTermsAndConditions() : null,
    (resource, init) => fetch(resource, init).then(res => res.json()),
  )

  const onSubmit = async (values: FormDataObject) => {
    if (
      !cvrData ||
      !basket.id ||
      isSubmitting ||
      termsAccepted !== 'ACCEPTED'
    ) {
      if (termsAccepted === 'IDLE') {
        setTermsAccepted('NOT_ACCEPTED')
      }
      return
    }

    setIsSubmitting(true)

    try {
      await updateAddressInfoInBasket(values)
    } catch ({ response, message }) {
      setErrorMessage(response?.data?.errorMessage || message)
      setIsSubmitting(false)
      return
    }

    try {
      const response = await finishCheckout(basket.id)
      const easyUrl = response.paymentInformation.hostedPaymentPageUrl
      window.location.href = easyUrl
    } catch ({ response, message }) {
      setErrorMessage(response?.data?.errorMessage || message)
      setIsSubmitting(false)
    }
  }

  const errorIcon = <ErrorFilledIcon className={classes.errorIcon} />

  const validate = (values: FormDataObject) => {
    const errors: Record<string, string> = {}

    const regularRequiredFields: (keyof FormDataObject)[] = ['attention']
    const requiredEmailFields: (keyof FormDataObject)[] = ['email']

    regularRequiredFields.forEach(keyName => {
      if (!values[keyName])
        errors[keyName] = translate('checkout.fieldMustBeFilled')
    })
    requiredEmailFields.forEach(keyName => {
      if (!values[keyName] || !validEmail(values[keyName]))
        errors[keyName] = translate('checkout.emailMustBeValid')
    })

    const keys = Object.keys(errors)
    return keys.length
      ? (() => {
          const errorsWithIcons: Record<string, React.ReactNode> = {}
          keys.forEach(
            key =>
              (errorsWithIcons[key] = (
                <>
                  {errorIcon}
                  {errors[key]}
                </>
              )),
          )
          return errorsWithIcons
        })()
      : undefined
  }

  const openTermsModal = (): void => setTermsModalOpen(true)
  const closeTermsModal = (): void => setTermsModalOpen(false)

  const basketHeader = (
    <Grid container justifyContent="space-between" wrap="nowrap">
      <Grid item>
        <Typography variant="h5" component="h2">
          {translate('checkout.basket.header')}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          component={RouterLink}
          to={routes.basket.route}
          variant="outlined"
          size="small"
        >
          {translate('checkout.basket.edit')}
        </Button>
      </Grid>
    </Grid>
  )

  const removeErrorMessage = (): void => {
    setErrorMessage('')
  }

  const selectCvr = (
    result: CompanyAddressData | null,
    setDefaultFromData?: boolean,
  ): void => {
    if (result) {
      if (result.isValid && !result.isBlackListed) {
        setCvrData(result)
        setCvrState('VALID')
        if (setDefaultFromData) {
          setDefaultCvrSearchValue(result)
        }
        if (basket.cvr !== result.cvrNumber) {
          basket.setAddressInfo({ cvr: result.cvrNumber })
        }
      } else {
        setCvrData(undefined)
        setCvrState('INVALID')
        basket.setAddressInfo({ cvr: '' })
      }
    } else {
      setCvrData(undefined)
      setCvrState('IDLE')
      basket.setAddressInfo({ cvr: '' })
    }
  }

  const loadCvrDataFromID = async (id: string) => {
    setCvrState('LOADING')
    try {
      const data = await getCompanyDataFromCVRNumber(id)
      selectCvr(data, true)
    } catch {
      setCvrState('IDLE')
    }
  }

  const onAcceptCheckboxSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTermsAccepted(event.target.checked ? 'ACCEPTED' : 'NOT_ACCEPTED')
  }

  useEffect((): void => {
    if (
      !basket.initializing &&
      (!basket.amount || basket.isAboveMaximumTotal)
    ) {
      // If the basket isn't initializing, and there's nothing in it, we should probably just go ahead and
      // redirect somewhere else.
      //TODO: Fix - this used to be ReactRouterDom
      // replaceHistoryEntry(routes.basket.route);
    }

    if (!basket.initializing && basket.cvr) {
      void loadCvrDataFromID(basket.cvr)
    }
  }, [basket.initializing, basket.lines, basket.cvr])

  return (
    <Layout>
      <Seo
        lang="da"
        title={`Checkud`}
        description="TDC Erhverv Shop"
        robot="none"
      />
      {errorMessage ? (
        <Dialog
          open={Boolean(errorMessage)}
          onClose={removeErrorMessage}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {translate('checkout.errorDuringCheckout')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>{errorMessage}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={removeErrorMessage} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container alignItems="flex-end" spacing={4}>
            <Grid item xs={12} md={8}>
              <Typography variant="h4" component="h1">
                {translate('checkout.invoice.header')}
              </Typography>
            </Grid>
            {isBigScreenOrLarger && (
              <Grid item md={4}>
                {basketHeader}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{
              attention: basket.attention || cvrData?.address.attention || '',
              email: basket.email,
              phone: basket.phone,
            }}
            render={({ handleSubmit }) => (
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Card className={classes.card}>
                  <Box className={classes.fieldWrapper}>
                    <Box mb={2}>
                      <CvrLookup
                        callback={selectCvr}
                        defaultValue={defaultCvrSearchValue}
                        label={translate('checkout.invoice.cvrNumber')}
                        noOptionsText={translate('checkout.cvr.noOptions')}
                        loadingText={translate('checkout.cvr.loading')}
                        loading={cvrState === 'LOADING'}
                      />
                    </Box>

                    {cvrState === 'INVALID' && (
                      <Box mt={3} mb={4}>
                        <ErrorMessage
                          message={translate('checkout.cvr.invalidText')}
                          title={translate('checkout.cvr.invalidHeader')}
                        />
                      </Box>
                    )}

                    {cvrData && (
                      <Box mt={3} mb={3}>
                        <Typography variant="body2">
                          {translate('checkout.deliveredToCompanyAddress')}
                        </Typography>
                        <Typography variant="subtitle1">
                          {cvrData.address.address}, {cvrData.address.zip}{' '}
                          {cvrData.address.city}
                        </Typography>
                      </Box>
                    )}

                    <FormControlLabel
                      control={
                        <Switch
                          id="identicalAddresses"
                          name="identicalAddresses"
                        />
                      }
                      label={translate(
                        'checkout.delivery.deliverToOtherAddress',
                      )}
                      checked={isAlternativeDeliveryAddress}
                      onChange={e =>
                        setIsAlternativeDeliveryAddress(
                          (e.target as HTMLInputElement).checked,
                        )
                      }
                    />

                    {isAlternativeDeliveryAddress && (
                      <Box mt={3} mb={5}>
                        <ErrorMessage
                          message={translate(
                            'checkout.delivery.deliverToOtherAddressForbidden',
                          )}
                        />
                      </Box>
                    )}

                    <TextField
                      {...sharedFieldProps}
                      required
                      id="invoice-attention"
                      name="attention"
                      label={translate('checkout.invoice.attention')}
                    />

                    <TextField
                      {...sharedFieldProps}
                      type="email"
                      required
                      id="invoice-email"
                      name="email"
                      label={translate('checkout.invoice.email')}
                    />

                    <TextField
                      {...sharedFieldProps}
                      type="tel"
                      id="invoice-phone"
                      name="phone"
                      label={translate('checkout.invoice.mobilePhone')}
                      helperText={translate(
                        'checkout.invoice.mobilePhoneHelpText',
                      )}
                    />
                  </Box>
                </Card>

                <Box mt={4} mb={4}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <Box mb={isMediumScreenOrLarger ? 4.2 : 2}>
                        {/*
                                                        You may wonder why we're "hardcoding" the acceptance checkbox instead of relying on the
                                                        form handler to... well, handle it. It's because for some reason, when you click "submit",
                                                        the "accept terms" checkbox sometimes just clears itself without explanation, which leads
                                                        to an ugly, red error being displayed to the user, even just for a short while. We can't
                                                        very well have that, so we'll handle the state ourselves instead. It's not data we need
                                                        for the basket or checkout anyway, it's just a sort of "gatekeeper".
                                                    */}
                        <FormControl error={termsAccepted === 'NOT_ACCEPTED'}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={termsAccepted === 'ACCEPTED'}
                                onChange={onAcceptCheckboxSwitch}
                                name="acceptTerms"
                              />
                            }
                            label={
                              <Typography variant="subtitle2" component="span">
                                {translate('checkout.terms.linkPrefix')}
                                &nbsp;
                                <button
                                  type="button"
                                  className={classes.termsButton}
                                  onClick={openTermsModal}
                                  title={translate(
                                    'checkout.terms.linkAriaTitle',
                                  )}
                                >
                                  {translate('checkout.terms.linkTitle')}
                                </button>
                              </Typography>
                            }
                          />
                          {termsAccepted === 'NOT_ACCEPTED' && (
                            <FormHelperText>
                              {errorIcon}
                              {translate('checkout.terms.error')}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <Dialog
                          open={termsModalOpen}
                          onClose={closeTermsModal}
                          aria-labelledby="terms-dialog-title"
                          aria-describedby="terms-dialog-description"
                        >
                          <DialogTitle id="terms-dialog-title">
                            {translate('checkout.terms.dialogTitle')}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText
                              id="terms-dialog-description"
                              tabIndex={-1}
                            >
                              {termsAndConditionsContent ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: termsAndConditionsContent,
                                  }}
                                />
                              ) : (
                                <CircularProgress />
                              )}
                            </DialogContentText>
                          </DialogContent>
                        </Dialog>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box mb={isMediumScreenOrLarger ? 4.2 : 2} aria-hidden>
                        <Typography
                          variant="body2"
                          align={isMediumScreenOrLarger ? 'right' : 'left'}
                        >
                          {translate('checkout.paymentMethods')}
                          &nbsp;
                          {[
                            <Dankort
                              className={classes.paymentIcon}
                              key="dankort"
                              style={{ width: 30 }}
                            />,
                            <Visa
                              className={classes.paymentIcon}
                              key="visa"
                              style={{ width: 30 }}
                            />,
                            <MasterCard
                              className={classes.paymentIcon}
                              key="master"
                              style={{ width: 25 }}
                            />,
                            <AmericanExpress
                              className={classes.paymentIcon}
                              key="amex"
                              style={{ width: 42 }}
                            />,
                          ].map((iconContent, idx) => (
                            <Box
                              className={classes.paymentIconWrapper}
                              boxShadow={1}
                              key={idx}
                            >
                              {iconContent}
                            </Box>
                          ))}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        component={RouterLink}
                        to={routes.basket.route}
                        variant="outlined"
                      >
                        {translate('checkout.backToBasket')}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          disabled={
                            termsAccepted !== 'ACCEPTED' ||
                            !basket.lines.length ||
                            isSubmitting ||
                            isAlternativeDeliveryAddress ||
                            basket.isAboveMaximumTotal ||
                            cvrState !== 'VALID'
                          }
                        >
                          {translate('checkout.continueToPayment')}
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {!isBigScreenOrLarger && basketHeader}
          <div className={classes.productList}>
            {basket.lines?.map(line => (
              <MiniBasketLineProductInfo
                key={line.variantId}
                data={line}
                readonly
              />
            ))}
          </div>

          <Box mt={3} mb={3}>
            <BasketPriceTable />
          </Box>

          <GetHelp />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default CheckoutPage
